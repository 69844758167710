.portfolio {
    margin: 3vh 2rem;
    background-color: #f5f5f7;
    font-size: 32px;

    &__content {
        font-size: 20px;
    }

    &__item {
        height: 25rem;
        backface-visibility: hidden;
    }

    @media (max-width: 1024px) {
        &__item {
           height: 18rem;
        }
    }

    @media (max-width: 540px) {
        &__item {
           min-width: 100% !important;
        }
    }
}

.card { 
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    perspective: 100rem;
    -moz-perspective: 100rem;
    position: relative;
    overflow: hidden;
    
    

    &-front {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        transition: all 0.8s;
        position: absolute;
        height: 100%;
        width: 100%;
        backface-visibility: hidden;

        &-title {
            font-size: 24px;
        }
    
        &-icon {
            height: 100px;
            margin-bottom: 20px;
        }
    
        &-desc {
            font-size: 18px;
        }

        &-tech {
            font-size: 14px;
            font-weight: 700;
        }
    }

    &-back {
        display: flex;
        justify-content: center;
        align-items: center;
        transform: rotateY(-180deg);
        transition: all 0.8s;
        position: absolute;
        backface-visibility: hidden;
        height: 100%;
        width: 100%;

        &-bg {
            height: 400px;
            
        }
    }

    &:hover &-front {
        transform: rotateY(180deg);
    }

    &:hover &-back {
        transform: rotateY(0);
    }
}

.snacks {
    height: 250px !important;
    margin-bottom: -130px;
    transform: translateY(-75px);
}

.tom {
    height: 140px !important;
    margin-bottom: -20px;
}

.astronaut {
    animation-name: float;
    animation-duration: 4s;
    animation-iteration-count:infinite;
}

.plane {
    animation-name: rotate;
    animation-duration: 3s;
    animation-iteration-count:infinite;
}

.logo {
    animation-name: spinUp;
    animation-duration: 3s;
    animation-iteration-count:infinite;
}

@keyframes float {
    0% { transform: translateY(15) }
    50% { transform: translateY(-10px) }
    100% { transform: translateY(15) }
}

@keyframes rotate {
    0% { transform: rotate(-6deg); }
    50% { transform: rotate(12deg); }
    100% { transform: rotate(-6deg); }
}

@keyframes spinUp {
    0% { opacity: 1; }
    15% { opacity: 1; }
    100% { opacity: 0; }
}