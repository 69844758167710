.header {
    height: 94vh;
    font-size: 40px;
    margin: 3vh 2rem;
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;

    &__title {
        font-family: 'Roboto Mono', monospace;
        width: 14em;
        margin: 10px 5%;
        border-right: 2px solid rgba(255,255,255,.75);
        font-size: 48px;
        white-space: nowrap;
        overflow: hidden;
    }

    &__wording {
        font-family: 'Lato', sans-serif;
        font-size: 28px;
        margin: 5px 5%;
    }

    &__content {
        width: 75% !important; 
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__shape {
        width: 18rem;
        height: 18rem;
        -webkit-clip-path: circle(50% at 50% 50%); //for firefox users
        clip-path: circle(50% at 50% 50%);
        // box-shadow: 0 2rem 3rem ;
        
    }

    &__img {
        height: 100%;
        box-shadow: 0 30px 60px #00000040;
    }

    @media (max-width: 720px) {
        .header {
            font-size: 20px !important;

            &__shape {
                width: 12rem;
                height: 12rem;
            }

            &__title {
                margin-top: 25%;
                font-size: 24px;
            }

            &__wording {
                font-size: 20px;
            }
        }
    }

}

.bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0.3;
    overflow: hidden;

    &__content {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

.anim-typewriter{
  animation: typewriter 1.8s steps(14) 1s 1 normal both,
             blinkTextCursor 400ms steps(44) infinite normal;
}

@keyframes typewriter{
  from{width: 0;}
  to{width: 8.5em;}
}

@keyframes blinkTextCursor{
  from{border-right-color: rgba(0,0,0,.75);}
  to{border-right-color: transparent;}
}
