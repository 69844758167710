.story {
    font-size: 32px;
    padding: 1% 15%;
    margin: 3vh 2rem;
    position: relative;

    &__header {
        padding: 1% 5%;
        border-bottom: 1px solid #00000020;
    }

    &__content {
        padding: 1% 5%;
    }

    &__quote {
        padding: 1% 0%;
        font-size: 1.1rem;
        font-style: italic;
    }

    &__author {
        font-size: 1.1rem;
        font-weight: 600;
    }

    @media (max-width: 720px) {
    
    }

}
