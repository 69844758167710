.skillset {
    margin: 3vh 2rem;
    background-color: #f5f5f7;
    font-size: 32px;
    padding: 3% 8%;
    position: relative;

    &__content {
        padding: 3% 5%;
        font-size: 20px;
    }

    &__item {
        height: 2rem;
        backface-visibility: hidden;

        &:hover {
            font-size: 22px;
            cursor: default;
            text-shadow: 1.2px 1.2px #00000030;
        }
    }
}
