.footer {
    text-align: center;
    display: flex;
    justify-content: center;
    font-size: 14px;

    &__group {
        width: 95%;
        padding-bottom: 1%;
        // border-top: 1px solid #00213B40;
        display: flex;
        justify-content: space-between;
    }

    &__links {
        padding-left: 5%;
        display: flex;
        flex-direction: row;
    }

    &__link {
        width: 150px;
        padding: 0 5%;
        border-right: 1px solid #00213B80;
        text-align: center;
        color: #00000080;

        &:hover {
            cursor: pointer;
            text-decoration: underline;
            color: #000;
        }

        &:last-child {
            border-right: none;
        }
    }

    @media (max-width: 1024px) {
        &__group {
            justify-content: center;
            flex-direction: column;
        }

        &__links {
            padding: 2% 0;
        }
    }
}