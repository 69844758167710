.form-section {
    font-size: 32px;
    background-color: #f5f5f7;
    padding: 4% 5% 5%;
    margin: 3vh 2rem;
    display: flex;
    flex-direction: column;

    &-header {
        padding: 0.5% 5%;
        margin: 2%;
        border-bottom: 1px solid #00000020;
    }
}

.form {
    display: flex;
    flex-direction: column;
    width: 40rem;
    

    &-input {
        font-size: 16px;
        font-weight: 500;
        font-family: 'Lato', sans-serif;
        padding: 16px 26px;
        margin: 1% 0;
        border: none;
        border-radius: 4px;
        transition: all 0.3s;
        border: 1px solid transparent;


        &:focus {
            outline: none;
            border: 1px solid #3269C3;
        }
    }

    &-textarea {
        font-size: 16px;
        font-weight: 500;
        font-family: 'Lato', sans-serif;
        padding: 16px 26px;
        margin: 1% 0;
        border: none;
        border-radius: 4px;
        resize: none;
        height: 12rem;
        line-height: 1.8;
        transition: all 0.5s;
        border: 1px solid transparent;

        &:focus {
            outline: none;
            border: 1px solid #3269C3;
        }
    }

    &-button {
        font-size: 16px;
        font-weight: 500;
        font-family: 'Lato', sans-serif;
        padding: 16px 26px;
        border: none;
        border-radius: 4px;
        margin-top: 1%;
        transition: all 0.3s;
        border: 1px solid transparent;
    
        &:focus {
            outline: none;
        }

        
    }

    .send {
        width: 19.5rem;
        margin-left: 1rem;
        background-color: #f5f5f7;

        &:hover {
            background-color: #b3e9cc90;
            box-shadow: 0 5px 10px #00000040;
            cursor: pointer;
        }
    }

    .clear {
        width: 19.5rem;
        background-color: #f5f5f7;

        &:hover {
            background-color:  #e4e4e4;
            box-shadow: 0 5px 10px #00000040;
            cursor: pointer;
        }
    }

    @media (max-width: 1024px) {
        width: 400px;

        .send {
            width: 195px;
            margin-left: 10px;
        }

        .clear {
            width: 195px;
        }
    }

    @media (max-width: 540px) {
        width: 300px;
        &-input {
            padding: 12px 18px;
        }

        &-textarea {
            padding: 12px 18px;
        }

        .send {
            background-color: #c2d1ec50;
            box-shadow: 0 5px 10px #00000040;;
            width: 300px;
            margin-left: 0;
        }

        .clear {
            width: 300px;
            // background-color: #ffdbdb30;
            box-shadow: 0 5px 10px #00000040;;
        }
    }
}